<template>
  <transition
    appear
    enter-active-class="transition ease-out duration-300"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class=""
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div class="flex flex-wrap lg:flex-no-wrap">
      <div v-for="(type, index) in types" :key="index" class="w-full lg:mx-5 my-5 lg:my-0 ">
        <ProfileTypeInput v-model="userForm.type" :type="type" />
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapState } from "vuex";
  import ProfileTypeInput from "@/components/shared/ProfileTypeInput"


  export default {
    name: "ProfileType",
    components: { ProfileTypeInput },
    data() {
      return {
        loading: false,
        types: ['guest', 'show', 'both']
      }
    },

    computed: {
      ...mapState('register',{
        userForm: state => state.userForm,
      }),
    },

    mounted() {
      this.$store.commit('register/setCurrentStep', 3)
    }
  }
</script>

<style scoped>

</style>
