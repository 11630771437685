<template>
  <label class="cursor-pointer flex flex-col justify-center items-center w-full rounded-md py-10 border-2 border-transparent"
         :class="value == type ? 'bg-white  border-indigo-500 shadow-2xl' : 'bg-guestio-gray-100'">
    <input type="radio" name="type" :value="type" class="hidden" @input="$emit('input',$event.target.value)">
    <component :is="`${toStartCase(type)}Icon`" :height="'46'"/>
    <span class="font-bold">{{toStartCase(type)}}</span>
  </label>
</template>

<script>
  import {startCase} from 'lodash';
  import GuestIcon from "../svgs/GuestIcon";
  import ShowIcon from "../svgs/ShowIcon";
  import BothIcon from "../svgs/BothIcon";

  export default {
    name: "ProfileTypeInput",
    components: {GuestIcon, ShowIcon, BothIcon},
    props: {
      value: {type: String, required: false, default: ''},
      type: {type: String, required: false, default: ''},
    },

    methods: {
      toStartCase(string) {
        return startCase(string)
      },
    },
  }
</script>
