<template>
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-6">
    <path
      d="M38.3332 40.25V36.4167C38.3332 34.3833 37.5254 32.4333 36.0877 30.9955C34.6499 29.5577 32.6998 28.75 30.6665 28.75H15.3332C13.2998 28.75 11.3498 29.5577 9.91202 30.9955C8.47424 32.4333 7.6665 34.3833 7.6665 36.4167V40.25"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M23.0002 21.0833C27.2343 21.0833 30.6668 17.6509 30.6668 13.4167C30.6668 9.18248 27.2343 5.75 23.0002 5.75C18.766 5.75 15.3335 9.18248 15.3335 13.4167C15.3335 17.6509 18.766 21.0833 23.0002 21.0833Z"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'GuestIcon',
}
</script>

<style lang="css" scoped></style>