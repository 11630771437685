<template>
  <svg width="74" height="46" viewBox="0 0 74 46" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-6">
    <path
      d="M38.3332 40.25V36.4167C38.3332 34.3833 37.5254 32.4333 36.0877 30.9955C34.6499 29.5577 32.6998 28.75 30.6665 28.75H15.3332C13.2998 28.75 11.3498 29.5577 9.91202 30.9955C8.47424 32.4333 7.6665 34.3833 7.6665 36.4167V40.25"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M23.0002 21.0833C27.2343 21.0833 30.6668 17.6509 30.6668 13.4167C30.6668 9.18248 27.2343 5.75 23.0002 5.75C18.766 5.75 15.3335 9.18248 15.3335 13.4167C15.3335 17.6509 18.766 21.0833 23.0002 21.0833Z"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M55 5.58325C53.7402 5.58325 52.532 6.0837 51.6412 6.97449C50.7504 7.86529 50.25 9.07347 50.25 10.3333V22.9999C50.25 24.2597 50.7504 25.4679 51.6412 26.3587C52.532 27.2495 53.7402 27.7499 55 27.7499C56.2598 27.7499 57.468 27.2495 58.3588 26.3587C59.2496 25.4679 59.75 24.2597 59.75 22.9999V10.3333C59.75 9.07347 59.2496 7.86529 58.3588 6.97449C57.468 6.0837 56.2598 5.58325 55 5.58325V5.58325Z"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M66.0832 19.8333V22.9999C66.0832 25.9394 64.9155 28.7585 62.8369 30.837C60.7584 32.9155 57.9393 34.0833 54.9998 34.0833C52.0604 34.0833 49.2413 32.9155 47.1627 30.837C45.0842 28.7585 43.9165 25.9394 43.9165 22.9999V19.8333"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M55 34L55 39" stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M48.6665 40.4167H61.3332" stroke="#6304D1" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BothIcon',
}
</script>

<style lang="css" scoped></style>