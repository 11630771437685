<template>
  <svg width="38" :height="height" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-6">
    <path
      d="M19 1.58325C17.7402 1.58325 16.532 2.0837 15.6412 2.97449C14.7504 3.86529 14.25 5.07347 14.25 6.33325V18.9999C14.25 20.2597 14.7504 21.4679 15.6412 22.3587C16.532 23.2495 17.7402 23.7499 19 23.7499C20.2598 23.7499 21.468 23.2495 22.3588 22.3587C23.2496 21.4679 23.75 20.2597 23.75 18.9999V6.33325C23.75 5.07347 23.2496 3.86529 22.3588 2.97449C21.468 2.0837 20.2598 1.58325 19 1.58325V1.58325Z"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M30.0832 15.8333V18.9999C30.0832 21.9394 28.9155 24.7585 26.8369 26.837C24.7584 28.9155 21.9393 30.0833 18.9998 30.0833C16.0604 30.0833 13.2413 28.9155 11.1627 26.837C9.08421 24.7585 7.9165 21.9394 7.9165 18.9999V15.8333"
      stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 30L19 35" stroke="#6304D1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.6665 36.4167H25.3332" stroke="#6304D1" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ShowIcon',
  props: {
    height: {
      type: String,
      default: '38',
      required: false
    }
  }
}
</script>

<style lang="css" scoped></style>